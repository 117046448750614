import { ErrorHandlerMixin } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { staticClass: "edit-oos-page", attrs: { "after-close": _vm.afterModalClose, "title": "Edit OOS Maintenance", "width": 832 }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.onCancelClick } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "type": "primary", "loading": _vm.isLoading }, on: { "click": function($event) {
        return handleSubmit(_vm.onSaveClick);
      } } }, [_vm._v(" OK ")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-icon", { staticClass: "minimize-icon", attrs: { "type": "minus" }, on: { "click": _vm.hideModal } }), _c("div", { staticClass: "header-title" }, [_vm._v("Item - " + _vm._s(_vm.entity.item))]), _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.entity.description))]), _c("a-row", { staticClass: "mt-5", attrs: { "gutter": 24 } }, [_c("a-col", { staticClass: "pb-3", attrs: { "span": 8 } }, [_c("date-picker", { attrs: { "label": "Start Date", "value": _vm.item.SDate, "disabled-date": _vm.disabledStartDate }, on: { "change": function($event) {
      return _vm.onDateChange("SDate", $event);
    } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 8 } }, [_c("date-picker", { attrs: { "label": "End Date", "value": _vm.item.EDate, "disabled-date": _vm.disabledEndDate }, on: { "change": function($event) {
      return _vm.onDateChange("EDate", $event);
    } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 8 } }, [_c("text-input", { attrs: { "label": "Facility", "span": 8, "disabled": true, "value": _vm.item.Facility }, on: { "change": function($event) {
      return _vm.storeValue("Facility", $event);
    } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 12 } }, [_c("select-input", { attrs: { "label": "Reason Code", "data-source": _vm.reasonCodes, "source": "reasonCode", "option-label-prop": "description", "value": _vm.item.OOSReason }, on: { "change": function($event) {
      return _vm.storeValue("OOSReason", $event);
    } }, scopedSlots: _vm._u([{ key: "data-source", fn: function() {
      return [_c("a-select-option", { attrs: { "value": "label", "disabled": "" } }, [_c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { staticStyle: { "font-weight": "bold", "color": "#272d35" }, attrs: { "span": 20 } }, [_vm._v(" Description ")]), _c("a-col", { staticStyle: { "font-weight": "bold", "color": "#272d35" }, attrs: { "span": 4 } }, [_vm._v(" Include ")])], 1)], 1), _vm._l(_vm.reasonCodes, function(code) {
        return _c("a-select-option", { key: code.reasonCode, attrs: { "value": code.reasonCode, "description": code.reasonCodeDesc } }, [_c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 20 } }, [_vm._v(" " + _vm._s(code.reasonCode) + " - " + _vm._s(code.reasonCodeDesc) + " ")]), _c("a-col", { attrs: { "span": 4 } }, [_vm._v(_vm._s(code.includeinCaseFill))])], 1)], 1);
      })];
    }, proxy: true }], null, true) })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 12 } }, [_c("text-input", { attrs: { "label": "Action/Support required", "span": 12, "value": _vm.item.ActionWho }, on: { "change": function($event) {
      return _vm.storeValue("ActionWho", $event);
    } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 12 } }, [_c("text-area", { attrs: { "label": "Root Cause", "span": 12, "value": _vm.item.RootCause }, on: { "change": function($event) {
      return _vm.storeValue("RootCause", $event);
    } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 12 } }, [_c("text-area", { attrs: { "label": "Last Update", "span": 12, "value": _vm.item.ActionRequired }, on: { "change": function($event) {
      return _vm.storeValue("ActionRequired", $event);
    } } })], 1)], 1), _c("div", { staticClass: "mt-3" }, [_c("div", { staticClass: "history-container mb-2" }, [_vm._v("History")]), _c("div", [_c("a-table", { attrs: { "data-source": _vm.comments, "bordered": "", "row-key": function(record) {
      return record.id;
    }, "scroll": { x: 1480 } }, on: { "change": _vm.onTableChange } }, [_c("a-table-column", { key: "StartDate", attrs: { "data-index": "startDate", "title": "Start Date", "sorter": true }, scopedSlots: _vm._u([{ key: "default", fn: function(text, record) {
      return [record.editable ? _c("date-picker", { attrs: { "value": text, "disabled-date": _vm.disabledStartDate }, on: { "change": function($event) {
        return _vm.onDateCommentChange($event, record.id, "startDate");
      } } }) : _c("text-field", { attrs: { "value": text, "parse": _vm.parseDate } })];
    } }], null, true) }), _c("a-table-column", { key: "EndDate", attrs: { "data-index": "endDate", "title": "End Date", "sorter": false }, scopedSlots: _vm._u([{ key: "default", fn: function(text, record) {
      return [record.editable ? _c("date-picker", { attrs: { "value": text, "disabled-date": _vm.disabledCommentEndDate(record) }, on: { "change": function($event) {
        return _vm.onDateCommentChange($event, record.id, "endDate");
      } } }) : _c("text-field", { attrs: { "value": text, "parse": _vm.parseDate } })];
    } }], null, true) }), _c("a-table-column", { key: "ReasonCode", attrs: { "data-index": "reasonDesc", "title": "Reason Code", "sorter": false }, scopedSlots: _vm._u([{ key: "default", fn: function(reasonDesc) {
      return [_c("text-field", { attrs: { "value": reasonDesc } })];
    } }], null, true) }), _c("a-table-column", { key: "OosComment", attrs: { "data-index": "oosComment", "title": "Comment", "sorter": false }, scopedSlots: _vm._u([{ key: "default", fn: function(text, record) {
      return [record.editable ? _c("text-input", { attrs: { "value": text }, on: { "change": function($event) {
        return _vm.handleEIRChange($event, record.id, "oosComment");
      } } }) : _c("text-field", { attrs: { "value": text } })];
    } }], null, true) }), _c("a-table-column", { key: "RootCase", attrs: { "data-index": "rootCause", "title": "Root Cause", "sorter": false }, scopedSlots: _vm._u([{ key: "default", fn: function(rootCause) {
      return [_c("text-field", { attrs: { "value": rootCause } })];
    } }], null, true) }), _c("a-table-column", { key: "ActionRequired", attrs: { "data-index": "actionRequired", "title": "Action Taken", "sorter": false }, scopedSlots: _vm._u([{ key: "default", fn: function(actionRequired) {
      return [_c("text-field", { attrs: { "value": actionRequired } })];
    } }], null, true) }), _c("a-table-column", { key: "ActionWho", attrs: { "data-index": "actionWho", "title": "Who", "sorter": false }, scopedSlots: _vm._u([{ key: "default", fn: function(actionWho) {
      return [_c("text-field", { attrs: { "value": actionWho } })];
    } }], null, true) }), _c("a-table-column", { attrs: { "width": 78, "align": "center", "title": "Action", "fixed": "right" }, scopedSlots: _vm._u([{ key: "default", fn: function(record) {
      return [record.id === _vm.editingKey ? [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
        return [_vm._v("Save")];
      }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "check", "size": "small", "type": "primary" }, on: { "click": function($event) {
        return _vm.onSaveEditComment(record);
      } } })], 1), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
        return [_vm._v("Cancel")];
      }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "close", "size": "small" }, on: { "click": function($event) {
        return _vm.onCancelEditComment(record.id);
      } } })], 1)] : _c("a-button", { attrs: { "icon": "edit", "size": "small", "type": "link", "disabled": !record.isEdit }, on: { "click": function($event) {
        return _vm.onEditComment(record.id);
      } } })];
    } }], null, true) })], 1)], 1)])], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
var EditOOSMaintaince_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".edit-oos-page .header-title[data-v-45299900]{font-style:normal;font-weight:500;font-size:24px;line-height:32px;color:#272d35}.edit-oos-page .title[data-v-45299900]{font-weight:400;font-size:14px;line-height:22px;color:#272d35;margin-top:10px}.edit-oos-page .history-container[data-v-45299900]{font-weight:500;font-size:16px;line-height:24px}.edit-oos-page .minimize-icon[data-v-45299900]{position:absolute;top:0;right:60px;color:#00000073;height:60px;width:40px;font-weight:700;line-height:60px;text-align:center;cursor:pointer;transition:all .5s ease}.edit-oos-page .minimize-icon[data-v-45299900]:hover{color:#000}.edit-oos-page .ant-btn-link[data-v-45299900]:hover{border-color:#0062ff;background-color:#0062ff;color:#fff}\n")();
const __vue2_script$1 = {
  name: "EditOOSMaintaince",
  inject: ["resourceProps"],
  mixins: [ErrorHandlerMixin],
  props: {
    country: {
      type: String,
      required: true
    },
    entityId: {
      type: Number,
      required: true
    }
  },
  data() {
    const [stockProps, reasonCodeProps, commentProps] = this.resourceProps;
    const apiUrl2 = stockProps.apiUrl;
    return {
      stockProps,
      reasonCodeProps,
      commentProps,
      apiUrl: apiUrl2,
      visible: false,
      isLoading: false,
      item: {},
      isMinimize: false,
      comments: [],
      cacheComments: [],
      editingKey: ""
    };
  },
  computed: {
    entity() {
      return this.stockProps.crud.getEntity();
    },
    reasonCodes() {
      return this.reasonCodeProps.crud.getList();
    }
  },
  watch: {
    entity(newVal) {
      const { item, facility, sDate, eDate, reasonCode } = newVal;
      this.storeValue("Item", item.trim());
      this.storeValue("Facility", facility.trim());
      this.storeValue("SDate", sDate);
      this.storeValue("EDate", eDate);
      this.storeValue("OOSReason", reasonCode);
      this.fetchCommentList();
    },
    comments(newVal) {
      const { sDate } = this.entity;
      const itemStartDate = this.$moment(sDate);
      const comment = newVal.find((comment2) => {
        const commentStartDate = this.$moment(comment2.startDate);
        const commentEndDate = this.$moment(comment2.endDate);
        return itemStartDate >= commentStartDate && itemStartDate <= commentEndDate;
      });
      if (comment) {
        const { startDate, endDate, actionWho, rootCause, actionRequired } = comment;
        const commentStartDate = this.$moment(startDate);
        const commentEndDate = this.$moment(endDate);
        this.storeValue("ActionWho", actionWho);
        this.storeValue("RootCause", rootCause);
        this.storeValue("ActionRequired", actionRequired);
        if (itemStartDate >= commentStartDate) {
          this.storeValue("SDate", startDate);
          this.storeValue("SDateOrig", startDate);
        }
        if (itemStartDate <= commentEndDate) {
          this.storeValue("EDate", endDate);
        }
      } else {
        this.storeValue("SDateOrig", sDate);
      }
    },
    editingKey(newVal, oldVal) {
      if (newVal && oldVal) {
        this.onCancelEditComment(oldVal);
        this.onEditComment(newVal);
      }
    }
  },
  mounted() {
    this.visible = true;
    this.reasonCodeProps.crud.fetchList();
    this.stockProps.crud.fetchEntity(`${this.apiUrl}/web-analytics/oo-out-of-stocks-stagings/${this.entityId}?country=${this.country}`);
  },
  methods: {
    disabledStartDate(current) {
      return current && current < this.$moment().subtract(6, "w");
    },
    disabledCommentEndDate(record) {
      return (current) => current && current < this.$moment(record.startDate).startOf("day");
    },
    disabledEndDate(current) {
      return current && current < this.$moment(this.item.SDate).startOf("day");
    },
    storeValue(resourceKey, value) {
      this.item = {
        ...this.item,
        [resourceKey]: value
      };
      this.stockProps.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
    },
    hideModal() {
      this.isMinimize = !this.isMinimize;
      this.visible = !this.visible;
      this.$notification.open({
        key: "minimize",
        message: "Editing OOS Maintenance",
        description: "Click to open",
        duration: null,
        onClick: () => this.onNotificationClick(),
        onClose: () => {
          this.isMinimize = !this.isMinimize;
          this.afterModalClose();
        }
      });
    },
    onDateChange(key, value) {
      if (key === "SDate") {
        this.storeValue("EDate", null);
      }
      this.storeValue(key, value);
    },
    onDateCommentChange(value, column, key) {
      if (key === "startDate") {
        this.handleEIRChange(null, column, "endDate");
      }
      this.handleEIRChange(value, column, key);
    },
    onNotificationClick() {
      this.visible = !this.visible;
      this.isMinimize = !this.isMinimize;
      this.$notification.close("minimize");
    },
    async onTableChange(_, __, sorter) {
      const { columnKey, order } = sorter;
      this.commentProps.crud.setSorter({ columnKey, order });
      this.fetchCommentList();
    },
    getQueryString(params) {
      let query = "";
      if (Object.keys(params).length === 0 || params.constructor !== Object) {
        return query;
      }
      Object.entries(params).forEach(([key, value]) => {
        query += value ? `${key}=${value}&` : "";
      });
      return query.slice(0, -1);
    },
    async fetchList(resourceProps, params = {}) {
      try {
        const { resourceName } = resourceProps;
        const resourcesHasQuery = [
          "web-analytics.oo-out-of-stocks-stagings",
          "web-analytics.common.reason-codes"
        ];
        const isOnlyQuery = !resourcesHasQuery.includes(resourceName);
        let query = this.getQueryString(params);
        let sorter = resourceProps.crud.getSorterQuery();
        if (sorter) {
          query += `&${sorter}`;
        }
        await resourceProps.crud.deleteFilter("IsInactive");
        await resourceProps.crud.fetchList(null, true, true, query, isOnlyQuery);
      } catch (err) {
        this.displayErrorNotification(err);
      }
    },
    async fetchCommentList() {
      try {
        await this.fetchList(this.commentProps, {
          country: this.country,
          item: this.item.Item,
          facility: this.item.Facility
        });
        this.comments = this.commentProps.crud.getList();
        this.cacheComments = this.comments.map((item) => ({ ...item }));
      } catch (err) {
        this.displayErrorNotification(err);
      }
    },
    fetchStockList() {
      this.fetchList(this.stockProps, {
        country: this.country
      });
    },
    parseDate(value) {
      if (!value)
        return "";
      return this.$moment(value).format("DD/MM/YYYY");
    },
    onSaveClick() {
      this.isLoading = true;
      this.stockProps.crud.submitEntity("replace", `${this.apiUrl}/web-analytics/oo-out-of-stocks-comments/${this.item.Item}?country=${this.country}`).then(() => {
        this.stockProps.crud.clearEntity("editForm");
        this.fetchStockList();
        this.visible = false;
      }).finally(() => {
        this.isLoading = false;
      });
    },
    onCancelClick() {
      this.visible = false;
    },
    afterModalClose() {
      if (!this.isMinimize)
        this.$router.push(this.stockProps.redirectRoute);
    },
    disabledDate(current) {
      return current && current < this.$moment().subtract(6, "w");
    },
    onEditComment(id) {
      this.editingKey = id;
      this.comments = this.comments.map((item) => {
        if (item.id === id)
          return { ...item, editable: true };
        return { ...item, editable: false };
      });
    },
    handleEIRChange(value, key, column) {
      let index2;
      const newData = [...this.comments];
      let target = newData.filter((item, i) => {
        if (item.id === key) {
          index2 = i;
          return item;
        }
      });
      target = target[0];
      if (target) {
        target[column] = value;
        newData[index2] = target;
        this.comments = newData;
      }
    },
    checkValidateDate(startDate, endDate) {
      let sDate = this.$moment(startDate);
      let eDate = this.$moment(endDate);
      if (sDate > eDate)
        return false;
      else
        return true;
    },
    onSaveEditComment(record) {
      const canSubmit = this.checkValidateDate(record.startDate, record.endDate);
      if (canSubmit) {
        const {
          id,
          item,
          facility,
          startDate,
          endDate,
          reasonID,
          oosComment,
          actionRequired,
          actionWho
        } = record;
        this.axios.put(`${this.apiUrl}/web-analytics/oo-out-of-stocks-comments/history-comment/${item}?country=${this.country}&facility=${facility}`, {
          id,
          facility,
          sDateOrig: startDate,
          sDate: startDate,
          eDate: endDate,
          OOSReason: reasonID,
          oosComment,
          actionRequired,
          actionWho
        }).then(() => {
          this.editingKey = "";
          this.fetchCommentList();
        }).catch((err) => this.displayErrorNotification(err));
      } else {
        this.$notification["error"]({
          message: "Start date must be earlier than the End date"
        });
      }
    },
    onCancelEditComment(id) {
      this.editingKey = "";
      const cacheComment = this.cacheComments.find((item) => item.id === id);
      if (cacheComment) {
        this.comments = this.comments.map((item) => {
          if (item.id === cacheComment.id)
            return cacheComment;
          return item;
        });
      }
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "45299900", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditOOSMaintaince = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-oos-maintaince" }, [_c("resource", { attrs: { "name": "web-analytics.common.reason-codes", "api-url": _vm.apiUrl, "resource-id-name": "reasonCode" } }), _c("resource", { attrs: { "name": [
    "web-analytics.oo-out-of-stocks-stagings",
    "web-analytics.common.reason-codes",
    "web-analytics.oo-out-of-stocks-comments"
  ], "api-url": _vm.apiUrl, "resource-id-name": ["id", "reasonCode", "startDate"], "redirect-route": ["/web-analytics/oo-out-of-stocks-stagings"] } }, [_c("edit-oos-maintainance", { attrs: { "entity-id": _vm.entityId, "country": _vm.country } })], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    "edit-oos-maintainance": EditOOSMaintaince
  },
  props: {
    country: {
      type: String,
      required: true
    },
    entityId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
